import React from "react";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import CardActions from "@material-ui/core/CardActions";
import NewProperty from "./NewProperty";

const Properties = ({ properties, isLoading }) => {
  return (
    <div>
      <NewProperty propps={properties} isLoading={isLoading} />
    </div>
  );
};

export default Properties;
