import ReactGA from "react-ga";

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

export const PageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const GA_Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label && label,
  });
};

export const subscribeAction = ({ userId, userName }) => {
  ReactGA.event({
    category: "Subscription",
    action: `${userName} - Clicked Subscription Button - ${userId}`,
  });
};

export const subscribeActionTwo = ({ userId, userName }) => {
  ReactGA.event({
    category: "Subscription",
    action: `${userName} - Clicked Subscription Button - ${userId}`,
    label: `userId: ${userId}`, // TODO add to user actions
  });
};

export const upgradeSubscritionAction = ({ userId, userName }) => {
  ReactGA.event({
    category: "Subscription",
    action: `${userName} - Clicked Upgrade Subscription Button - ${userId}`,
  });
};

export const upgradeSubscritionActionHolder = ({ userId, userName }) => {
  ReactGA.event({
    category: "Subscription",
    action: `${userName} - Clicked Upgrade Subscription Button - ${userId}`,
    "By User": `${userName} - ${userId}`,
  });
};

export const addCallboxAction = ({ userId, userName }) => {
  ReactGA.event({
    category: "User Interactions",
    action: `${userName} - Clicked Button - ${userId}`,
  });
};

export const newRegistrationsAction = () => {
  ReactGA.event({
    category: "User Interactions",
    action: "User clicked register",
  });
};

export const copyReferralLinkAction = ({ userId, userName }) => {
  ReactGA.event({
    category: "User Interactions",
    action: `${userName} - Clicked referral link - ${userId}`,
  });
};

export const cancelSubscriptionAction = ({ userId, userName }) => {
  ReactGA.event({
    category: "Subscription",
    action: `${userName} - Clicked Cancel Subscription Button - ${userId}`,
  });
};

export const searchAction = (searchTerm) => {
  ReactGA.event({
    category: "User Interactions",
    action: "User Searched",
    label: `Searched: ${searchTerm}`,
  });
};

export const homeNav = () => {
  ReactGA.event({
    category: "User Interactions",
    action: "Home button Clicked",
  });
};

export const favoritesNav = () => {
  ReactGA.event({
    category: "User Interactions",
    action: "Favorites button Clicked",
  });
};

export const accountNav = () => {
  ReactGA.event({
    category: "User Interactions",
    action: "Accounts button Clicked",
  });
};

export const expanAction = (podName) => {
  ReactGA.event({
    category: "User Interactions",
    action: "User clicked expand button",
    label: `Expanded podcast: ${podName}`,
  });
};
