import React from "react";
import { Link } from "react-router-dom";

const FAQ = () => {
  return (
    <div>
      <div className="row">
        <div
          style={{
            fontSize: "38px",
            fontWeight: "bold",
            color: "#6262a0",
            textAlign: "center",
          }}
        >
          FAQ
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Buzz Jarvis Explanation Video For you
          </div>
          <div
            className="videoWrapper"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <iframe
              src={`https://www.youtube.com/embed/wK17d3YTvu4`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "40px",
            }}
          >
            What is BuzzJarvis?
          </div>
          <p
            style={{
              maxWidth: "520px",
              textAlign: "center",
              fontSize: "0.975rem",
            }}
          >
            BuzzJarvis is a service that gives you control over your ordinary
            apartment intercom or buzzer system by adding access codes and
            getting intercom access notifications.
          </p>

          <div
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "40px",
            }}
          >
            How does it work?
          </div>
          <p
            style={{
              maxWidth: "520px",
              textAlign: "center",
              fontSize: "0.975rem",
            }}
          >
            Once you’ve signed up for an account, we’ll give you a phone number
            (BuzzJarvis Number) you can give to your building manager to use
            with the callbox Intercom system. (Based on your settings)* Upon
            guests arrival automatically buzz in guests or have your guest enter
            the intercom access code you set for entry.
          </p>

          <div
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "40px",
            }}
          >
            What is a BuzzJarvis number?
          </div>
          <p
            style={{
              maxWidth: "520px",
              textAlign: "center",
              fontSize: "0.975rem",
            }}
          >
            A BuzzJarvis number is a custom local phone number that we provide
            you on sign up. You give it to your building or property manager at
            the end of the signup process and it will tie your callbox Intercom
            to BuzzJarvis services.
          </p>

          <div
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "40px",
            }}
          >
            Payment Methods?
          </div>
          <p
            style={{
              maxWidth: "520px",
              textAlign: "center",
              fontSize: "0.975rem",
            }}
          >
            We have a range of different plans. We accept all major credit
            cards. You can cancel at any time no questions asked.
          </p>

          <div
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "40px",
            }}
          >
            Still need assistance?
          </div>
          <div
            style={{
              maxWidth: "520px",
              textAlign: "center",
              fontSize: "0.975rem",
            }}
          >
            Please &nbsp;
            <Link to="/contact">
              <u>Contact Us.</u>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
