import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";
import MainInput from "../helpers/MainInput";
import Loading from "../helpers/Loading";
import { PageView } from "../helpers/GoogleAnalytics";
import queryString from "query-string";

const StyledHeading = styled("h2")`
  font-size: 62px;
  font-weight: 700;
  margin-top: 2vh;
  margin-bottom: 24px;
  text-align: center;
  line-height: 1.1;
  color: #b6b6b5;
`;

export const GreenButton = styled("button")`
  align-items: center;
  user-select: none;
  display: inline-flex;
  justify-content: center;
  padding: 6px 16px;
  flex-shrink: 0;
  border-radius: 3px;
  font-weight: 500;
  background: rgba(235, 87, 87, 0.03);
  color: #00a591;
  border: 1px solid #00a591;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  width: 170px;
  margin-top: 6px;
  margin-bottom: 12px;
  cursor: pointer;
  &:hover {
    background-color: #00a591;
    color: #fff;
  }
`;

const StyledRedSpan = styled("span")`
  color: red;
`;

const StyledGreenSpan = styled("span")`
  color: green;
`;

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      name: "",
      email: "",
      password: "",
      password2: "",
      signUpError: "",
      signUpSuccess: "",
    };

    this._isMounted = false;
  }

  timer = () =>
    setTimeout(() => {
      const { name, email, password } = this.state;

      const userObj = {
        name,
        email,
        password,
      };

      axios
        .post("/api/login", userObj)
        .then((res) => {
          if (res.data.success) {
            this._isMounted &&
              this.setState({
                name: "",
                email: "",
                password: "",
                password2: "",
                signUpError: "",
                isLoading: false,
              });
          } else {
            this._isMounted &&
              this.setState({
                signInError: res.data.message,
                isLoading: false,
              });
          }
        })
        .catch((err) => {
          console.log(err);
          this._isMounted &&
            this.setState({
              signUpError:
                err.response.data.message ||
                "Email and Password does not match or This is a gmail account or Account does not exist.",
              isLoading: false,
            });
        });

      // this.props.fetchUser();

      this.props.history.push("/register/new");
    }, 1000);

  componentDidMount = () => {
    this._isMounted = true;
    window.scrollTo(0, 0);
    PageView();
    if (this.state.isLoading) {
      this._isMounted &&
        this.setState({
          isLoading: false,
        });
    }
  };

  handleChange = (event) => {
    this._isMounted &&
      this.setState({
        [event.target.name]: event.target.value,
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this._isMounted &&
      this.setState({
        isLoading: true,
      });

    const { name, email, password } = this.state;
    const now = moment().format("dddd, MMMM Do YYYY, h:mm a");
    const ref = queryString.parse(this.props.location.search).ref;
    const userObj = {
      name: name.toLowerCase(),
      email: email.toLowerCase(),
      password: password,
      ref: ref && ref,
    };

    axios
      .post("/api/register", userObj)
      .then((res) => {
        if (res.data.success) {
          this.timer();
          axios
            .post(`/api/v/new/reg`, { name, email, now })
            .then((res) => {})
            .catch((error) => {
              console.log(error);
              this.setState({
                signUpError: res.data.message,
                isLoading: false,
              });
            });
        } else {
          this._isMounted &&
            this.setState({
              signUpError: res.data.message,
              isLoading: false,
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    clearTimeout(this.timer);
  };

  redirectToSuccess = () => {
    this.props.history.push("/register/new");
  };

  render() {
    const { signUpError, isLoading, signUpSuccess } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <StyledHeading>Register</StyledHeading>

        <form onSubmit={this.handleSubmit}>
          <br />
          <MainInput
            label="FULL NAME"
            type="text"
            name="name"
            disabled={this.state.isLoading}
            placeholder="John Doe..."
            value={this.state.name}
            required
            onChange={this.handleChange}
          />

          <br />
          <MainInput
            label="EMAIL"
            type="email"
            name="email"
            disabled={this.state.isLoading}
            placeholder="example@example.com..."
            value={this.state.email}
            required
            onChange={this.handleChange}
          />

          <br />
          <MainInput
            label="PASSWORD"
            type="password"
            name="password"
            disabled={this.state.isLoading}
            placeholder="Enter your password here..."
            value={this.state.password}
            required
            onChange={this.handleChange}
          />

          {isLoading ? (
            <div style={{ textAlign: "center" }}>
              <GreenButton>
                <Loading />
              </GreenButton>
            </div>
          ) : (
            <div style={{ textAlign: "center", marginTop: "25px" }}>
              <GreenButton onClick={this.handleSubmit}>Register</GreenButton>
            </div>
          )}
        </form>

        {signUpError ? (
          <div>
            <StyledRedSpan>{signUpError}</StyledRedSpan>
          </div>
        ) : null}

        {signUpSuccess ? (
          <div>
            <StyledGreenSpan>{signUpSuccess}</StyledGreenSpan>
          </div>
        ) : null}
      </div>
    );
  }
}

export default RegisterPage;
