import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import * as actions from "../../actions";
import SuperTable from "../contentComps/SuperTable";
import CircleLoader from "../helpers/CircleLoader";
import { Link } from "react-router-dom";
import styled from "styled-components";

class Super extends Component {
  state = {
    isLoading: true,
    userProps: [],
    totalUsers: "",
    totalProperties: "",
    totalSubs: "",
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    try {
      const { data } = await axios.get("/api/insights");

      this.setState({
        userDetails: data.userDetails,
        totalUsers: data.totalUsers,
        totalProps: data.totalProps,
        totalSubs: data.totalSubs,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      console.log(err);
    }
  };

  render() {
    const { isLoading, userDetails, totalUsers, totalProps, totalSubs } =
      this.state;
    return (
      <div>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <div>Total Users: {totalUsers}</div>
          <div>Total Properties: {totalProps}</div>
          <div>Total Subs: {totalSubs}</div>
          <br />
          <div id="superactions">
            <Link to="/dashboard">
              <MyButton variant="contained" color="primary">
                My Property Activity
              </MyButton>
            </Link>
            <br />
            <Link to="/dashboard/all">
              <MyButton
                variant="contained"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                All Property Activity
              </MyButton>
            </Link>
          </div>
        </div>
        <div style={{ marginBottom: "30px" }}>
          {isLoading ? (
            <CircleLoader />
          ) : (
            <SuperTable userDetails={userDetails} />
          )}
        </div>
      </div>
    );
  }
}

const MyButton = styled.button`
  min-height: 50px;
  min-width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  padding: 20px;
  border-radius: 5px;
  background-color: #6262a0;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #7272aa;
  }
`;

function mapStateToProps(state) {
  return {
    auth: state.auth,
    properties: state.properties,
  };
}

export default connect(mapStateToProps, actions)(Super);
