import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import Tooltip from "@material-ui/core/Tooltip";
import TapAndPlayIcon from "@material-ui/icons/TapAndPlay";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import DialpadIcon from "@material-ui/icons/Dialpad";
import swal from "sweetalert";
import { GA_Event } from "../helpers/GoogleAnalytics";
import { handleCallBoxUpdate } from "../../actions";
import CircleLoader from "../helpers/CircleLoader";

import EditIcon from "@material-ui/icons/Edit";
import { isAuth } from "../../utils/AuthHelper";

const useStyles = makeStyles((theme) => ({
  cards: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridGap: "10px",
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "auto",
      flexDirection: "column",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    width: "560px",
    margin: "30px 15.25px 15.25px 15.25px",
    minHeight: "150px",
    borderRadius: "10px",
    padding: "16px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      width: "280px",
    },
  },
  cardContent: {
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: 18,
    color: "#6262a0",
    fontWeight: 900,
    textTransform: "capitalize",
  },
  title2: {
    fontSize: 14,
    color: "#6262a0",
    fontWeight: 900,
    textTransform: "capitalize",
  },
  subtitle: {
    color: "gray",
    fontSize: 13,
  },
  subtitle2: {
    color: "gray",
    fontSize: 11,
  },
  subtitle3: {
    color: "gray",
    fontSize: 11,
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  details: {
    margin: "15px 10px",
    height: "80px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      height: "auto",
    },
  },
  detail: {
    display: "flex",
    alignItems: "center",
    margin: "15px 20px",
  },
  mobileSub: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginLeft: "10px",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  icons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  powerIconDiv: {
    padding: "7px",
    height: "37px",
    color: "#fff",
    width: "37px",
    backgroundColor: "#50C878",
    borderRadius: "50%",
    cursor: "pointer",
  },
}));

const NewProperty = ({ propps, history, isLoading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = isAuth();

  const goToPropertyEdit = (prop) => {
    history.push({
      pathname: `/property/${prop._id}/edit`,
      state: {
        propertyId: prop._id,
        isSecure: prop.codeRequired,
        property: prop,
      },
    });
  };

  const turnOffService = async (propp) => {
    let message = "";
    let message2 = "";
    const newPropp = {
      name: propp.name,
      codeRequired: propp.codeRequired,
      isLocked: propp.isLocked,
      _id: propp._id,
      _user: propp._user,
      serviceCall: true,
    };

    dispatch(handleCallBoxUpdate(newPropp));

    if (propp.isLocked && !propp.codeRequired) {
      message = "Service Enabled";
      GA_Event(
        "Product interactions",
        "Security Code Enabled",
        `By User: ${auth._id}`
      );
      message2 = "Calls made at your call box will automatically open";
    } else if (propp.isLocked && propp.codeRequired) {
      message = "Service Enabled with Security Code";
      GA_Event(
        "Product interactions",
        "Security Code Enabled with Security Code",
        `By User: ${auth._id}`
      );
      message2 = `Calls made at your call box will require security code: ${propp.securityCode}`;
    } else {
      message = "Service Disabled";
      GA_Event(
        "Product interactions",
        "Security Code Disabled",
        `By User: ${auth._id}`
      );
      message2 =
        "Calls made at your call box will go to your phone like normal";
    }
    swal(message, message2, "success");
  };

  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return null;
  };

  return (
    <div>
      <div className="row" style={{ textAlign: "center" }}>
        <h3 style={{ color: "gray" }}>
          {propps && propps.length
            ? "Your Call Box(s)"
            : "No call boxes have been added"}
        </h3>
      </div>
      <div className={classes.cards}>
        {propps &&
          propps.map((propp) => (
            <div key={propp._id} className={classes.card}>
              {isLoading ? (
                <CircleLoader />
              ) : (
                <>
                  <div className={classes.icons}>
                    <Tooltip
                      title={`Callbox is ${
                        propp.isLocked ? "Offline" : "Online"
                      }`}
                      placement="top"
                    >
                      <div
                        style={{
                          backgroundColor: `${
                            !propp.isLocked ? "#50C878" : "lightcoral"
                          }`,
                          width: "10px",
                          height: "10px",
                          borderRadius: "100%",
                          boxShadow: `0px 0px 10px 0px ${
                            !propp.isLocked ? "#50C878" : "lightcoral"
                          }`,
                        }}
                      ></div>
                    </Tooltip>
                    <EditIcon
                      onClick={() => goToPropertyEdit(propp)}
                      style={{ cursor: "pointer", color: "gray" }}
                    />
                  </div>

                  <div className={classes.details}>
                    <Tooltip title="Callbox Name and Address" placement="top">
                      <div className={classes.detail}>
                        <HomeWorkIcon style={{ color: "#6262a0" }} />
                        <div style={{ marginLeft: "10px" }}>
                          <div className={classes.title}>{propp.name}</div>
                          <div className={classes.subtitle}>
                            {propp.address}
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title="Callbox Number, Give this to the Property Management"
                      placement="top"
                    >
                      <div className={classes.detail}>
                        <TapAndPlayIcon style={{ color: "#6262a0" }} />
                        <div style={{ marginLeft: "10px" }}>
                          <div className={classes.title2}>
                            {formatPhoneNumber(propp.twilioPhone)}
                          </div>
                          <div className={classes.subtitle2}>
                            Give this number to Property Mgmt.
                          </div>
                        </div>
                      </div>
                    </Tooltip>

                    <Tooltip
                      title="Cell Phone Buzz-In (This is usually '9')"
                      placement="top"
                    >
                      <div className={classes.detail}>
                        <DialpadIcon style={{ color: "#6262a0" }} />
                        <div
                          style={{
                            marginLeft: "10px",
                            fontWeight: !propp.codeRequired ? "bold" : 800,
                            color: "#6262a0",
                          }}
                        >
                          <div className={classes.title2}>
                            {propp.gateCodeDialNumber}
                          </div>
                          <div className={classes.subtitle3}>
                            CellPhone Buzz-In number
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                    {propp.codeRequired && (
                      <Tooltip
                        title="An Access Code is required to Buzz-In to this callbox/Property"
                        placement="top"
                      >
                        <div className={classes.detail}>
                          <LockOutlinedIcon style={{ color: "#6262a0" }} />
                          <div className={classes.mobileSub}>
                            <div className={classes.title2}>Locked</div>
                            <div className={classes.subtitle2}>
                              Requires Access Code
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  <div className={classes.icons}>
                    <div style={{ fontSize: "13px", color: "gray" }}></div>
                    <Tooltip
                      title="Take callbox offline and forward all buzz-ins to phone attached to property"
                      placement="top"
                    >
                      <div
                        style={{
                          padding: "7px",
                          height: "37px",
                          color: "#fff",
                          width: "37px",
                          backgroundColor: `${
                            propp.isLocked ? "#50C878" : "lightcoral"
                          }`,
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        onClick={() => turnOffService(propp)}
                      >
                        <div>
                          <PowerSettingsNewIcon elevation={3} />
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default withRouter(NewProperty);
