import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import styled from "styled-components";
import { upgradeSubscritionAction } from "../helpers/GoogleAnalytics";
import CircleLoader from "../helpers/CircleLoader";
import { isAuth } from "../../utils/AuthHelper";

const useStyles = makeStyles((theme) => ({
  cards: {},
  card: {
    width: "350px",
    margin: "10px 0",
    height: "373px",
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
  cardContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 14,
    textTransform: "captilize",
    textAlign: "center",
    color: "#6262a0",
    fontWeight: "bold",
  },
  price: {
    fontSize: 40,
    color: "#6262a0",
    fontWeight: 600,
    textAlign: "center",
  },
  pos: {
    display: "flex",
    marginBottom: 12,
  },
}));

const StatCard = ({
  history,
  title,
  count,
  auth,
  realCount = 0,
  isLoading,
  last,
}) => {
  const classes = useStyles();

  const goToBillingPage = () => {
    if (auth.plan === "premium") {
      upgradeSubscritionAction({userId: isAuth()?._id, userName: isAuth()?.name});
      history.push("/contact");
    } else {
      upgradeSubscritionAction({userId: isAuth()?._id, userName: isAuth()?.name});
      history.push("/account");
    }
  };

  return (
    <div>
      <div className={classes.cards}>
        <Card className={classes.card}>
          {isLoading ? (
            <CircleLoader />
          ) : (
            <CardContent className={classes.cardContent}>
              <div>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {title}
                </Typography>
                <Typography className={classes.price}>
                  {realCount}/{count}
                </Typography>
                <CardActions>
                  <SubscribeButton onClick={() => goToBillingPage()}>
                    {isAuth().plan === "premium"
                      ? "Contact Us To Upgrade"
                      : "Upgrade"}
                  </SubscribeButton>
                </CardActions>
              </div>
              <div style={{ fontSize: "12px", color: "gray" }}>{last}</div>
            </CardContent>
          )}
        </Card>
      </div>
    </div>
  );
};

const SubscribeButton = styled.button`
  min-height: 50px;
  min-width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #6262a0;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #7272aa;
  }
`;

export default withRouter(StatCard);
