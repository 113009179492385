export const isEmpty = (data) => {
  if (typeof data === "undefined") {
    return true;
  } else if (!data) {
    return true;
  } else if (data.trim() === "") {
    return true;
  } else if (/\S/.test(data) === "") {
    return true;
  } else {
    return false;
  }
};
