import React from "react";
import SmallInput from "../helpers/SmallInput";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";

const PremiumAccessCode = ({
  handleSecurityChange,
  isLoading,
  formValues,
  addFormFields,
  removeFormFields,
}) => {
  return (
    <div>
      {formValues.map((item, i) => {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "15px",
              transition: "all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s",
            }}
          >
            <SmallInput
              label="Who/People"
              type="text"
              help="Who or what group of people is the access code being created for?"
              name="reason"
              disabled={isLoading}
              placeholder="ie. Dogwalker, Cleaners, etc..."
              value={item.reason}
              onChange={(e) => handleSecurityChange(i, e)}
            />
            <SmallInput
              label="Access Code"
              help="This is a up to 6-Digit code that guests will be required to enter access code ie. '123456' at the call box in order for BuzzJarvis to allow them entry"
              name="code"
              disabled={isLoading}
              placeholder="ie. 1234"
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
              }}
              value={item.code}
              onChange={(e) => handleSecurityChange(i, e)}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <CloseIcon
                  style={{
                    color: "lightcoral",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => removeFormFields(i)}
                />
              </div>

              <div style={{ marginLeft: "10px", minWidth: "24px" }}>
                {formValues.length - 1 === i && (
                  <AddCircleIcon
                    style={{
                      color: "#50C878",
                      marginTop: "20px",
                      cursor: "pointer",
                    }}
                    onClick={addFormFields}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PremiumAccessCode;
