import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { withRouter, Link } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import { isAuth, signOut } from "../../utils/AuthHelper";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: "#6262A0",
    fontWeight: 900,
    fontSize: 38,
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  dropdownMenuItem: {
    margin: "0 15px 0 0",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    color: "#6262a0",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const PrimaryAppBar = ({ history }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const goToPage = (page) => {
    history.push(`/${page}`);
    handleMenuClose();
  };

  const goToPageMobile = (page) => {
    history.push(`/${page}`);
    handleMobileMenuClose();
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuCloseLogOut = async () => {
    setMobileMoreAnchorEl(null);

    signOut(() => {
      window.location.reload();
    });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenuCloseLogOut = async () => {
    setAnchorEl(null);
    handleMobileMenuClose();

    signOut(() => {
      history.push("/login");
    });
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      style={{ marginTop: "55px" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {isAuth() && (
        <MenuItem onClick={() => goToPage("account")}>
          <PersonOutlinedIcon className={classes.dropdownMenuItem} /> Account
        </MenuItem>
      )}
      <MenuItem onClick={() => goToPage("contact")}>
        <EmailOutlinedIcon className={classes.dropdownMenuItem} /> Contact Us
      </MenuItem>
      <MenuItem onClick={() => goToPage("faq")}>
        <LiveHelpOutlinedIcon className={classes.dropdownMenuItem} /> FAQs
      </MenuItem>

      {isAuth() && (
        <MenuItem onClick={handleMenuCloseLogOut}>
          <span style={{ display: "flex", height: "30px", color: "#6262a0" }}>
            <PowerSettingsNewOutlinedIcon
              className={classes.dropdownMenuItem}
            />{" "}
            <span>Log Out</span>
          </span>
        </MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      style={{ marginTop: "55px" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {isAuth() && (
        <MenuItem onClick={() => goToPageMobile("account")}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Account</p>
        </MenuItem>
      )}
      <MenuItem onClick={() => goToPageMobile("contact")}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <EmailOutlinedIcon />
        </IconButton>
        <p>Contact Us</p>
      </MenuItem>
      <MenuItem onClick={() => goToPageMobile("faq")}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <LiveHelpOutlinedIcon />
        </IconButton>
        <p>Need Help?</p>
      </MenuItem>
      {isAuth() && (
        <MenuItem
          onClick={handleMobileMenuCloseLogOut}
          style={{ display: "flex", marginLeft: "11px" }}
        >
          <span
            style={{
              color: "#6262a0 !important",
              marginRight: "12px",
              display: "flex",
            }}
          >
            <PowerSettingsNewOutlinedIcon />
          </span>
          <p>Log Out</p>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Link to="/home">
            <Typography className={classes.title} noWrap>
              BuzzJarvis
            </Typography>
          </Link>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Link to="/faq">
              <IconButton
                style={{
                  margin: "0 5px",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#6262a0",
                  textTransform: "capitalize",
                  marginTop: "3px",
                }}
              >
                Need Help?
              </IconButton>
            </Link>

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {isAuth() && (
                <AccountCircle
                  style={{ margin: "0 10px 0 0", color: "#6262a0" }}
                />
              )}
              {isAuth() ? (
                <div
                  style={{
                    margin: "0 5px",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#6262a0",
                    textTransform: "capitalize",
                  }}
                >
                  Hi, {isAuth().name}
                </div>
              ) : null}
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

export default withRouter(PrimaryAppBar);
