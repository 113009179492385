import React, { Component } from "react";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Loading from "../helpers/Loading";
import CircleLoader from "../helpers/CircleLoader";
import { authenticate, isAuth } from "../../utils/AuthHelper";
// import GoogleButton from "./GoogleButton";

const StyledHeading = styled("h2")`
  font-size: 25px;
  font-weight: 700;
  margin-top: 2vh;
  margin-bottom: 24px;
  text-align: center;
  line-height: 1.1;
  color: #b6b6b5;
`;

const LoginButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #6262a0;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #7272aa;
  }
`;

export const HoverDiv = styled("span")`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StyledRedSpan = styled("span")`
  color: red;
`;

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      email: "",
      password: "",
      signInError: "",
    };
    this._isMounted = false;
  }

  componentDidMount = async () => {
    this._isMounted = true;
    // console.log(this.props.history);
    window.scrollTo(0, 0);
    if (this.state.isLoading) {
      this._isMounted &&
        this.setState({
          isLoading: false,
        });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    clearTimeout(this.timer);
  };

  setErrorMessage = (err) => {
    this._isMounted &&
      this.setState({
        signInError: err,
        isLoading: false,
      });
  };

  setLoading = (loadingState) => {
    this._isMounted &&
      this.setState({
        isLoading: Boolean(loadingState),
      });
  };

  informParent = (response) => {
    authenticate(response, () => {
      this.setLoading(false);
      isAuth()
        ? this.props.history.push("/home")
        : this.props.history.push("/login");
    });
  };

  timer = () =>
    setTimeout(() => {
      this._isMounted &&
        this.setState({
          email: "",
          password: "",
          signInError: "",
          isLoading: false,
        });

      // this.props.fetchUser();

      try {
        this.props.history.push("/home");
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }, 250);

  handleChange = (event) => {
    this._isMounted &&
      this.setState({
        [event.target.name]: event.target.value,
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this._isMounted &&
      this.setState({
        isLoading: true,
      });

    const { email, password } = this.state;

    const userObj = {
      email: email.toLowerCase(),
      password: password,
    };

    axios
      .post("/api/login", userObj)
      .then((res) => {
        if (res.data.success) {
          authenticate(res, () => {
            setTimeout(() => {
              this._isMounted &&
                this.setState({
                  email: "",
                  password: "",
                  signInError: "",
                  isLoading: false,
                });

              try {
                this.props.history.push("/home");
              } catch (err) {
                console.log(err);
              }
            }, 550);
          });
        } else {
          this._isMounted &&
            this.setState({
              signInError:
                "Email and Password does not match or This is a gmail account.",
              isLoading: false,
            });
        }
      })
      .catch((err) => {
        console.log(err);

        this._isMounted &&
          this.setState({
            signInError:
              "Email and Password does not match or This is a gmail account.",
            isLoading: false,
          });
      });
  };

  render() {
    const { signInError, isLoading } = this.state;

    return (
      <div>
        <div id="loginPage">
          <div id="imageSection">
            <img
              src="https://i.postimg.cc/xd4bK68Q/newloginpng.png"
              alt="login-page"
              style={{ width: "800px", marginTop: "25px" }}
            />
          </div>
          {isAuth() && isAuth().plan ? <Redirect to="/home" /> : null}
          <div id="loginSection">
            <StyledHeading>Log In</StyledHeading>
            {isLoading ? (
              <CircleLoader />
            ) : (
              <>
                <form onSubmit={this.handleSubmit}>
                  <br />

                  <TextField
                    label="Email"
                    variant="outlined"
                    type="email"
                    name="email"
                    placeholder="example@example.com..."
                    disabled={this.state.isLoading}
                    value={this.state.email}
                    onChange={this.handleChange}
                    required
                    style={{ width: "280px" }}
                  />
                  <br />

                  <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    name="password"
                    placeholder="Enter your password here..."
                    disabled={this.state.isLoading}
                    value={this.state.password}
                    onChange={this.handleChange}
                    required
                    style={{ width: "280px", marginTop: "20px" }}
                  />

                  {isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <LoginButton>
                        <Loading />
                      </LoginButton>
                    </div>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <LoginButton type="submit" value="Submit">
                        Log In
                      </LoginButton>
                    </div>
                  )}
                </form>

                {signInError ? (
                  <div
                    style={{
                      width: "280px",
                      textAlign: "center",
                      fontSize: "12px",
                      marginTop: "10px",
                    }}
                  >
                    <StyledRedSpan>{signInError}</StyledRedSpan>
                  </div>
                ) : null}

                <div style={{ color: "#b6b6b5", marginTop: 20 }}>- Or -</div>

                {/* <div>
                  <GoogleButton
                    informParent={this.informParent}
                    setErrorMessage={this.setErrorMessage}
                    setLoading={this.setLoading}
                  />
                </div> */}
                <div style={{ marginTop: 20 }}>
                  <span style={{ color: "#b6b6b5" }}>
                    Don't already have an account?
                  </span>{" "}
                  <Link to="/register" style={{ color: "#0080AD" }}>
                    <HoverDiv>Register</HoverDiv>
                  </Link>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Link to="/forgot" style={{ color: "#0080AD" }}>
                    <HoverDiv>Forgot Password?</HoverDiv>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
