import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import MainInput from "../helpers/MainInput";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { PageView, GA_Event } from "../helpers/GoogleAnalytics";
import Switch from "@material-ui/core/Switch";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import CircleLoader from "../helpers/CircleLoader";
import PremiumAccessCode from "./PremiumAccessCode";
// import BasicAccessCode from "./BasicAccessCode";
import { isEmpty } from "../helpers/EmptyChecck";
import { isAuth } from "../../utils/AuthHelper";

const StyledHeading = styled("h2")`
  font-size: 22px;
  font-weight: 700;
  margin-top: 2vh;
  margin-bottom: 24px;
  text-align: center;
  line-height: 1.1;
  color: #b6b6b5;
`;

const PurpleSwitch = withStyles({
  switchBase: {
    color: "lightcoral",
    "&$checked": {
      color: "#f6b2b2",
    },
    "&$checked + $track": {
      backgroundColor: "#f6b2b2",
    },
  },
  checked: {},
  track: {},
})(Switch);

const AddProperty = ({ history }) => {
  const [values, setValues] = useState({
    propName: "",
    address: "",
    securityCode: "",
    enableSecurity: false,
    gateCodeDialNumber: "9",
    message: "",
    customerPhone: "",
    errorMsg: "",
    propData: [],
    propId: "",
  });
  const [checkedA, setCheckedA] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amtAllowed, setAmtAllowed] = useState(2);
  const {
    errorMsg,
    propName,
    address,
    securityCode,
    gateCodeDialNumber,
    message,
    customerPhone,
  } = values;

  const [formValues, setFormValues] = useState([{ reason: "", code: "" }]);

  const handleSecurityChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const addFormFields = () => {
    if (amtAllowed <= formValues.length) {
      setValues({
        ...values,
        errorMsg:
          "You have reached your limit for access codes, please upgrade or contact us for more.",
      });
      return;
    }

    setFormValues([...formValues, { reason: "", code: "" }]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setValues({
      ...values,
      errorMsg: "",
    });
  };

  const handleToggle = () => {
    setCheckedA(!checkedA);
    if (formValues.length < 1) {
      setFormValues([...formValues, { reason: "", code: "" }]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userId = isAuth().id ?? isAuth()._id;
    GA_Event(
      "Product interactions",
      `${isAuth()?.name} - Created Property - ${userId}`,
      `By User: ${userId}`
    );

    if (
      isEmpty(propName) ||
      isEmpty(address) ||
      isEmpty(customerPhone) ||
      isEmpty(gateCodeDialNumber) ||
      isEmpty(customerPhone)
    ) {
      setValues({ ...values, errorMsg: "Please fill in all required fields" });
      return;
    }

    setIsLoading(true);

    const propObj = {
      name: propName,
      address,
      securityCode,
      gateCodeDialNumber,
      customerPhone,
      codeRequired: checkedA,
      securityCodes: formValues,
    };

    try {
      const { data } = await axios.post("/api/property/add", propObj);

      if (data.success) {
        history.push("/home");
      } else if (!data.success) {
        setValues({
          ...values,
          errorMsg: data.message,
        });
      }
    } catch (err) {
      setValues({
        ...values,
        errorMsg:
          err.response.data.message ||
          "Invalid Customer or Callbox Number, U.S. numbers, Please try again later or contact us",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    PageView();
    const alld = isAuth().plan === "premium" ? 5 : 2;
    setAmtAllowed(alld);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
      }}
    >
      {isLoading > 0 ? (
        <CircleLoader />
      ) : (
        <div>
          <form onSubmit={handleSubmit}>
            <StyledHeading>Add CallBox</StyledHeading>
            <br />
            <MainInput
              label="Property name *"
              type="text"
              name="name"
              disabled={isLoading}
              help="A unique way for you to identify this specific property"
              placeholder="ex. Walton Riverwood Apartments Call Box..."
              value={propName}
              required
              onChange={handleChange("propName")}
            />
            <br />
            <MainInput
              label="Property Address *"
              type="text"
              name="address"
              disabled={isLoading}
              help="This is used only for your assistance in remembering where the call box/gate is located"
              placeholder="ex. 2000 Univerisity Pkwy... Just to help you identify properties..."
              value={address}
              required
              onChange={handleChange("address")}
            />
            <br />
            <MainInput
              label="Your Phone Number *"
              type="number"
              name="customerPhone"
              disabled={isLoading}
              help="This your phonenumber or any number that will be used to send you updates such as when the door/gate is open."
              placeholder={`ex. ${
                isAuth().phoneNumber
              }... notifications will be texted to this number`}
              value={customerPhone}
              required
              onChange={handleChange("customerPhone")}
            />

            <br />
            <MainInput
              label="Cell Phone Buzz-In Number *"
              type="number"
              name="gateCodeDialNumber"
              disabled={isLoading}
              help="This is the number provided to you from the property management to dial on your cell phone to buzz open the gate for your guest to come in, ...ie. '9'"
              placeholder="ex. '9'... Number that you'd dial at call box to open gate..."
              value={gateCodeDialNumber}
              required
              onChange={handleChange("gateCodeDialNumber")}
            />
            <br />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  transition:
                    "all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s",
                }}
              >
                <PurpleSwitch
                  checked={checkedA}
                  onChange={() => handleToggle()}
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>

              <Tooltip
                title="If disabled, When guest dials your name at call box, BuzzJarvis number will automatically buzz in the guest. If enabled, guests will be required to enter security pin ie. '12345' at the call box in order for BuzzJarvis to allow them entry"
                placement="top"
              >
                <div>
                  {checkedA ? "Access Code(s)" : "Require Access Code(s)?"}
                </div>
              </Tooltip>
              {checkedA && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <LockOutlinedIcon style={{ fontSize: "16px" }} /> &nbsp;{" "}
                  {formValues.length}
                  /5
                </div>
              )}
            </div>

            {checkedA ? (
              <PremiumAccessCode
                handleSecurityChange={handleSecurityChange}
                isLoading={isLoading}
                setFormValues={setFormValues}
                formValues={formValues}
                addFormFields={addFormFields}
                removeFormFields={removeFormFields}
              />
            ) : null}

            <br />
            <div style={{ textAlign: "center", marginBottom: "25px" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                value="Submit"
                onClick={handleSubmit}
                style={{
                  minHeight: "50px",
                  minWidth: "100px",
                  marginTop: "20px",
                  cursor: "pointer",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "#6262a0",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "600",
                  transition:
                    "all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s",
                  "&:hover": {
                    backgroundColor: "#7272aa",
                  },
                }}
              >
                {isLoading ? "Loading..." : "Add Callbox"}
              </Button>
            </div>
            <div
              style={{
                fontSize: "10px",
                color: "red",
                textAlign: "center",
                width: "280px",
              }}
            >
              <span>{errorMsg && errorMsg}</span>
            </div>
            <div
              style={{
                fontSize: "10px",
                color: "green",
                textAlign: "center",
                width: "280px",
              }}
            >
              <span>{message && message}</span>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default withRouter(AddProperty);
