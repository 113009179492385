import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    paddingTop: "30px",
    paddingBottom: theme.spacing(2),
    borderTop: "1px solid #dadada",
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    color: "#b3b3b3",
    width: "80%",
    boxShadow: "unset",
  },
  footer: {
    marginTop: "37% !important",
    display: "flex",
    justifyContent: "center",
  },
});

function Footer(props) {
  const { classes } = props;

  return (
    <footer className={classes.footer}>
      <Paper className={classes.root} elevation={1}>
        <Typography component="p">BuzzJarvis</Typography> &nbsp;
        <Typography component="p">&copy; 2024 All right reserved</Typography>
      </Paper>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
