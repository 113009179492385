import React, { Component } from "react";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

const Label = styled("div")`
  font-size: 11px;
  line-height: 16px;
  text-transform: capitalize;
  letter-spacing: 0.05em;
  color: #b6b6b5 !important;
  font-weight: 500;
`;

const InputContainer = styled("div")`
  display: flex;
  align-items: center;
  width: 100px;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  padding: 4px 10px;
  position: relative;
  border-radius: 3px;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset,
    rgba(15, 15, 15, 0.1) 0px 1px 1px inset;
  background: transparent;
  cursor: text;
  margin-top: 4px;
  margin-bottom: 4px;
  &:disabled {
    color: #505050;
    font-weight: 200;
    font-size: 12px;
  }
`;

const InputWithOutBorder = styled("input")`
  font-size: inherit;
  line-height: inherit;
  border: none;
  background: none;
  width: 100%;
  outline: none;
  display: block;
  resize: none;
  padding: 0px;
  &::placeholder {
    color: #b6b6b5;
    font-weight: 200;
    font-size: 12px;
  }
  &:disabled {
    color: #505050;
    font-weight: 200;
    font-size: 12px;
  }
`;

class SmallInput extends Component {
  render() {
    const { label, help, ...inputProps } = this.props;

    return (
      <div>
        <div>
          <Label
            style={{
              color: "#b6b6b5 !important",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {label}

            {help ? (
              <Tooltip title={help}>
                <HelpIcon size="small" fontSize="small" />
              </Tooltip>
            ) : null}
          </Label>
        </div>
        <InputContainer>
          <InputWithOutBorder {...inputProps} />
        </InputContainer>
      </div>
    );
  }
}

export default SmallInput;
