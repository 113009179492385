import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StatCard from "./StatCard";
import ActionBox from "./ActionBox";
import axios from "axios";
import Properties from "./Properties";
import { fetchProps } from "../../actions";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  cards: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  card: {
    width: "350px",
    margin: "10px 0",
    height: "460px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
  cardContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 14,
    textTransform: "uppercase",
    textAlign: "center",
    color: "#6262a0",
  },
  price: {
    fontSize: 70,
    color: "#6262a0",
    fontWeight: 600,
    textAlign: "center",
  },
  pos: {
    display: "flex",
    marginBottom: 12,
  },
}));

const SubbedHome = ({ auth, setAMessage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.properties);
  const [callboxCount, setCallboxCount] = useState(2);
  const [buzzCount, setBuzzCount] = useState(200);
  const [counts, setCounts] = useState(0);
  const [lastActivityString, setActivityString] = useState("");
  const [lastCreateString, setLastCreateString] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const setUpCounts = () => {
    switch (auth.plan) {
      case "premium":
        setCallboxCount(3);
        setBuzzCount(400);
        break;

      default:
        break;
    }
  };

  const setUpTrialCount = () => {
    setCallboxCount(1);
    setBuzzCount(10);
  };

  const fetchCounts = async () => {
    const { data } = await axios.get(`/api/counts`);
    let lastAct = "No Buzz-Ins yet...";
    let lastCreate = "You have not created any callboxes yet...";
    if (data.lastActivity) {
      lastAct = `Last Buzz-In was ${moment(data.lastActivity)
        .startOf("minute")
        .fromNow()}`;
    }
    if (data.lastCreated) {
      lastCreate = `Last Callbox created ${moment(data.lastCreated)
        .startOf("minute")
        .fromNow()}`;
    }

    if (data.callboxCount < 1) {
      setAMessage(true);
    }
    setCounts(data);
    setActivityString(lastAct);
    setLastCreateString(lastCreate);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (auth.isTrial || auth.subscription) {
      dispatch(fetchProps());
    }
    if (!auth.subscription && auth.isTrial) {
      setUpTrialCount();
    } else {
      setUpCounts();
    }
    fetchCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div>
        <Properties
          properties={properties}
          last={lastActivityString}
          isLoading={isLoading}
        />
      </div>
      <div style={{ margin: "50px 0" }}>
        <hr style={{ border: "1px solid #e0e0e0" }} />
      </div>
      <div className={classes.cards}>
        <ActionBox auth={auth} counts={counts} />
        <StatCard
          title="Buzz-Ins For the Month"
          count={buzzCount}
          realCount={counts.buzzCount}
          last={lastActivityString}
          auth={auth}
          isLoading={isLoading}
        />
        <StatCard
          title="Callboxes"
          count={callboxCount}
          realCount={counts.callboxCount}
          last={lastCreateString}
          auth={auth}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default withRouter(SubbedHome);
