import React, { Component } from "react";

class RegisterSuccessPage extends Component {
  timer = () =>
    setTimeout(() => {
      this.props.history.push("/home");
    }, 2000);

  componentDidMount = () => {
    this.timer();
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  };

  render() {
    return (
      <div
        className="row"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <div className="center">
          <blockquote>
            <h3 className="fontweight" style={{ color: "#b6b6b5" }}>
              Welcome... You are almost there!{" "}
            </h3>
            <span style={{ color: "#b6b6b5" }}>
              You will be redirected momentarily...
            </span>
          </blockquote>
        </div>
      </div>
    );
  }
}

export default RegisterSuccessPage;
