import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { isAuth } from "../../utils/AuthHelper";
import SubbedHome from "./SubbedHome";
import UnsubbedHome from "./UnsubbedHome";
// import UnSubbedRow from "./UnSubbedRow";

const useStyles = makeStyles((theme) => ({
  welcomeText: {
    color: "green",
    fontSize: 25,
    fontWeight: 100,
    [theme.breakpoints.down("md")]: {
      // display: "block",
      fontSize: 16,
      fontWeight: 500,
    },
  },
  welcomeTextSub: {
    color: "green",
    fontSize: 25,
    fontWeight: 100,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      // display: "block",
      fontSize: 16,
      fontWeight: 500,
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const properties = useSelector((state) => state.properties);
  const [isSubbedWithNoProperties, setMessage] = useState(false);
  const [title, setTitle] = useState("Buzz Jarvis At Your Service!");

  const setSubbedWithNoCallBoxMessage = (bool) => {
    setMessage(bool);
  };

  const setATitle = () => {
    if (!isAuth().subscription && isAuth().isTrial && isAuth.freeTrialEndDate) {
      setTitle(
        `Buzz Jarvis At Your Service - Your Free Trial expires ${moment(
          isAuth().freeTrialEndDate
        )
          .startOf("minute")
          .fromNow()}`
      );
    }
  };

  useEffect(() => {
    setATitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="row">
        <PageTitle>{isAuth().isTrial && "Dashboard"}</PageTitle>
        {isAuth().isTrial || isAuth.subscription ? (
          <span className={classes.welcomeText}> - {title}</span>
        ) : null}
      </div>
      {isSubbedWithNoProperties && (
        <>
          <div className={classes.welcomeTextSub}>{title}</div>
          <div
            style={{
              color: "blue",
              fontWeight: "bolder",
              margin: "20px 0",
              textAlign: "center",
            }}
          >
            Add a callbox and we'll provide you with a number to give to your
            property management
          </div>
        </>
      )}

      {true ? (
        <SubbedHome
          properties={properties}
          auth={isAuth()}
          setAMessage={setSubbedWithNoCallBoxMessage}
        />
      ) : (
        <div>
          <>
            <UnsubbedHome auth={isAuth()} />
          </>
          {/* <div
            style={{
              textAlign: "center",
              fontSize: 40,
              fontWeight: 800,
              color: "#6262a0",
              marginTop: 120,
            }}
          >
            Our Bundle Packages
          </div>
          <>
            <UnSubbedRow auth={isAuth()} />
          </> */}
        </div>
      )}
    </div>
  );
};

const PageTitle = styled.span`
  font-size: 25px;
  color: gray;
  font-weight: 600;
`;

export default Home;
