import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#6262a0",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
    fontWeight: 600,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
}));

export default function CustomizedTable({ userDetails }) {
  const classes = useStyles();

  const gateActionText = (status) => {
    switch (status) {
      case 1:
        return "Callbox Opened";
      case 2:
        return "Invalid Call/Callbox Not Opened";
      case 3:
        return "Callbox forwarded to your phone number";
      default:
        return;
    }
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Property Name</StyledTableCell>
              <StyledTableCell>Action By</StyledTableCell>
              <StyledTableCell align="right">Call Box Action</StyledTableCell>
              <StyledTableCell align="right">DateTime</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userDetails &&
              userDetails.map((user) => (
                <StyledTableRow hover key={user._id}>
                  <StyledTableCell component="th" scope="row">
                    {user.PropertyName}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {user.reason}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {gateActionText(user.isOpen)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {moment(user.createdAt).calendar()}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
