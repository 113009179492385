import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { red } from "@material-ui/core/colors";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 360,
    margin: "40px 0",
    padding: "2%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
  bold: {
    fontWeight: 800,
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();

  console.log(props.others.lastBuzzActivity, props.others.monthlyUsage);

  const GetAcronym = (name) => {
    const str = name;
    const matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    const acronym = matches.join(""); // JSON
    return acronym;
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {GetAcronym(props.usr.name)}
          </Avatar>
        }
        title={props.usr.name}
        subheader={`Created: ${moment(props.usr.createdAt).format(
          "MMM Do, YYYY"
        )}`}
      />
      <CardContent>
        <span className={classes.bold}>gateCodeDialNumber:</span>{" "}
        {JSON.stringify(props.usr.gateCodeDialNumber)}
        <br />
        <span className={classes.bold}>IsAccessCodeRequired:</span>{" "}
        {JSON.stringify(props.usr.codeRequired)}
        <br />
        <span className={classes.bold}>securityCode:</span>{" "}
        {JSON.stringify(props.usr.securityCode)}
        <br />
        <span className={classes.bold}>twilioPhone:</span>{" "}
        {JSON.stringify(props.usr.twilioPhone)}
        <br />
        <span className={classes.bold}>isCallBoxOnline:</span>{" "}
        {JSON.stringify(props.usr.archieved)}
        <br />
        <span className={classes.bold}>isLocked:</span>{" "}
        {JSON.stringify(props.usr.isLocked)}
        <br />
        <span className={classes.bold}>customerPhoneNumber:</span>{" "}
        {JSON.stringify(props.usr.customerPhone)}
        <br />
        <span className={classes.bold}>monthlyUsage:</span>{" "}
        {JSON.stringify(props.others.monthlyUsage)}
        <br />
        <span className={classes.bold}>lastBuzzActivity:</span>{" "}
        {moment(props.others.lastBuzzActivity).startOf("minute").fromNow()}
        <br />
      </CardContent>
    </Card>
  );
}
