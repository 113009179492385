import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outlineStyle: "none",
    width: "85%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px",
  },
  form: {
    width: "270px",
  },
}));

const ReferralsModal = ({ referrals }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span onClick={handleOpen} className="hover">
        Successful Referrals: {referrals && referrals.length}
      </span>
      <br />
      <br />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        disabled={referrals.length > 0}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {referrals.length ? (
              <>
                <h2>Your Successful Referrals</h2>
                {referrals.map((referral) => {
                  return (
                    <span key={referral._id} className="flex">
                      <DoneIcon
                        fontSize="small"
                        style={{ color: "green", marginBottom: "5px" }}
                      />{" "}
                      &nbsp; {referral.invitedEmail}
                    </span>
                  );
                })}
              </>
            ) : (
              <div>You do not have any successful referrals at the moment.</div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ReferralsModal;
