import React, { Component } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import * as actions from "../../actions";
import DashboardTable from "../contentComps/DashboardTable";
import styled from "styled-components";
import CircleLoader from "../helpers/CircleLoader";

const StyledHeading = styled("h2")`
  font-size: 22px;
  font-weight: 700;
  margin-top: 2vh;
  margin-bottom: 24px;
  text-align: center;
  line-height: 1.1;
  color: #b6b6b5;
`;

class Dashboard extends Component {
  state = {
    isLoading: true,
    userProps: [],
    totalUsers: "",
    totalProperties: "",
  };

  componentDidMount = async () => {
    try {
      const { data } = await axios.get("/api/activity");

      this.setState({
        userDetails: data,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      console.log(err);
    }
  };

  render() {
    const { isLoading, userDetails } = this.state;
    return (
      <div>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <StyledHeading>Your Property Callbox Activities</StyledHeading>
        </div>
        <div style={{ marginBottom: "30px" }}>
          {isLoading ? (
            <CircleLoader />
          ) : (
            <DashboardTable userDetails={userDetails} />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    properties: state.properties,
  };
}

export default connect(mapStateToProps, actions)(Dashboard);
