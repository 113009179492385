import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import swal from "sweetalert";
import axios from "axios";
import { GA_Event } from "../helpers/GoogleAnalytics";
import CircleLoader from "../helpers/CircleLoader";
import { removeLocalStorage } from "../../utils/AuthHelper";

const GeneralComp = ({ auth }) => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteAcct = () => {
    GA_Event(
      "User Interactions",
      "User Clicked delete Account",
      "USER_INTERACTIONS"
    );
    setIsLoading(true);

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this account",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! account deleted!", {
          title: "Successfully Deleted Account",
          buttons: false,
          timer: 3000,
        });
        axios
          .post("/api/delete")
          .then((res) => {
            if (res.data.success === true) {
              removeLocalStorage("user");
              window.location.href = "https://app.buzzjarvis.com/login";
              window.location.reload();
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      } else {
        swal("Your Account has not been deleted!");
        setIsLoading(false);
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "60px",
      }}
    >
      {isLoading ? (
        <CircleLoader />
      ) : (
        <>
          <div
            style={{ fontSize: "38px", fontWeight: "bold", color: "#6262a0" }}
          >
            Profile
          </div>
          <div>
            <TextField
              label="Name"
              variant="outlined"
              value={auth.name}
              disabled
              style={{
                width: "280px",
                marginTop: "40px",
                textTransform: "capitalize",
              }}
            />
          </div>
          <div>
            <TextField
              label="Email"
              variant="outlined"
              value={auth.email}
              disabled
              style={{ width: "280px", marginTop: "40px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <CancelButton onClick={() => deleteAcct()}>
              Delete Account
            </CancelButton>
          </div>
        </>
      )}
    </div>
  );
};

const CancelButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: red;
  }
`;

export default GeneralComp;
