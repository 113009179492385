import { combineReducers } from "redux";
import authReducer from "./authReducer";
import propertyReducer from "./propertyReducer";
import singlePropReducer from "./singlePropReducer";

export default combineReducers({
  auth: authReducer,
  properties: propertyReducer,
  property: singlePropReducer
});
