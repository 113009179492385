import React, { Component } from "react";
// import styled from "styled-components";

// const StyledDiv = styled("div")`
//   background-color: #000;
// `;
class Loading extends Component {
  render() {
    return (
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
}

export default Loading;
