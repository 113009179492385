import React, { Component } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import * as actions from "../../actions";
import DashboardTable from "../contentComps/DashboardTable";
import CircleLoader from "../helpers/CircleLoader";

class AllDashboard extends Component {
  state = {
    isLoading: true,
    userProps: [],
    totalUsers: "",
    totalProperties: ""
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    try {
      const { data } = await axios.get("/api/activity/all");

      this.setState({
        userDetails: data,
        isLoading: false
      });
    } catch (err) {
      this.setState({
        isLoading: false
      });
      console.log(err);
    }
  };

  render() {
    const { isLoading, userDetails } = this.state;
    return (
      <div>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <h3>Your Property Callbox Activities</h3>
        </div>
        <div style={{ marginBottom: "30px" }}>
          {isLoading ? (
            <CircleLoader />
          ) : (
            <DashboardTable userDetails={userDetails} />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    properties: state.properties
  };
}

export default connect(mapStateToProps, actions)(AllDashboard);
