import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import StripeCheckout from "react-stripe-checkout";
import swal from "sweetalert";
import axios from "axios";
import { withRouter } from "react-router-dom";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import styled from "styled-components";
import {
  PageView,
  subscribeAction,
  cancelSubscriptionAction,
} from "../helpers/GoogleAnalytics";
import CircleLoader from "../helpers/CircleLoader";
import { setLocalStorage } from "../../utils/AuthHelper";
import { isAuth } from "../../utils/AuthHelper";

const useStyles = makeStyles((theme) => ({
  cards: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  card: {
    width: "350px",
    margin: "10px 0",
    height: "460px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
  cardContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 14,
    textTransform: "capitalize",
    textAlign: "center",
    color: "#6262a0",
    fontWeight: "bold",
  },
  price: {
    fontSize: 70,
    color: "#6262a0",
    fontWeight: 600,
    textAlign: "center",
  },
  pos: {
    display: "flex",
    marginBottom: 12,
  },
}));

const BillingComp = ({ auth, history }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    PageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToken = async ({ token, amount }) => {
    const tokenItems = {
      source: token.id,
      ccLast4: token.card.last4,
      amount,
    };
    cancelSubscriptionAction({
      userId: isAuth()?._id,
      userName: isAuth()?.name,
    });
    setIsLoading(true);
    try {
      const res = await axios.post("/api/subscriptions/new", tokenItems);
      console.log(res);
      setLocalStorage("user", res.data);
      window.location.reload(false);
      window.location.href = "/login";
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const goToPageContact = () => {
    subscribeAction({ userId: isAuth()?._id, userName: isAuth()?.name });
    history.push("/contact");
  };

  const justWait = () =>
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);

  const cancelSub = async () => {
    cancelSubscriptionAction({
      userId: isAuth()?._id,
      userName: isAuth()?.name,
    });
    setIsLoading(true);

    await swal({
      title: "Cancelling?",
      text: "Once Cancelled, you will not be able to create properties and calls will be forwarded to your phone",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Your Subscription has been cancelled!", {
          title: "Successfully Cancelled Subscription.",
          buttons: false,
          timer: 3000,
        });
        const res = await axios.post("/api/subscription/cancel");
        setLocalStorage("user", res.data);
        window.location.reload(false);
        window.location.href = "/login";
        justWait();
      } else {
        swal("Phew!, We thought we lost you there.");
        setIsLoading(false);
      }
    });
  };

  return (
    <div>
      {isLoading ? (
        <CircleLoader />
      ) : (
        <>
          <div
            style={{
              fontSize: "38px",
              fontWeight: "bold",
              color: "#6262a0",
              textAlign: "center",
            }}
          >
            Subscription Plans
          </div>
          <div className={classes.cards}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <div>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Basic
                  </Typography>
                  <Typography className={classes.price}>$9.99</Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckBoxOutlinedIcon
                      style={{ margin: "0 10px 0 20px", color: "#1dbe74" }}
                    />{" "}
                    200 Buzz-Ins Per Month
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckBoxOutlinedIcon
                      style={{ margin: "0 10px 0 20px", color: "#1dbe74" }}
                    />{" "}
                    Up to 2 Callboxes
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckBoxOutlinedIcon
                      style={{ margin: "0 10px 0 20px", color: "#1dbe74" }}
                    />{" "}
                    2 Access Codes Per Callbox
                  </Typography>
                </div>
                <div>
                  <CardActions>
                    <StripeCheckout
                      name="Buzz Jarvis"
                      amount={999}
                      description={`Only $9.99 a month`}
                      currency="USD"
                      panelLabel="Subscribe Now" //Submit button in modal
                      image="https://i.postimg.cc/pTMFdqdL/noun-interphone-3907992.png"
                      token={async (token) => onToken({ token, amount: 999 })}
                      stripeKey={"pk_live_Xyw2DT9ExfQiRGkPSot9JH2J00fo5YItIK"}
                      // pk_test_DViw7ncmbz3aZqg6NfWwiB6D00TEA0N7uH
                    >
                      <SubscribeButton
                        style={{
                          backgroundColor: `${
                            !auth.subscription
                              ? "#6262a0"
                              : auth.plan === "premium"
                              ? null
                              : "lightcoral"
                          }`,
                        }}
                      >
                        {isLoading ? (
                          <CircleLoader />
                        ) : (
                          `${
                            !auth.subscription
                              ? "Subscribe"
                              : auth.plan === "premium"
                              ? "Downgrade"
                              : "Update Credit Card"
                          }`
                        )}
                      </SubscribeButton>
                    </StripeCheckout>
                  </CardActions>
                </div>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <div>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Premium
                  </Typography>
                  <Typography className={classes.price}>$16.99</Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckBoxOutlinedIcon
                      style={{ margin: "0 10px 0 20px", color: "#1dbe74" }}
                    />{" "}
                    400 Buzz-Ins Per Month
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckBoxOutlinedIcon
                      style={{ margin: "0 10px 0 20px", color: "#1dbe74" }}
                    />{" "}
                    Up to 3 Callboxes
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckBoxOutlinedIcon
                      style={{ margin: "0 10px 0 20px", color: "#1dbe74" }}
                    />{" "}
                    5 Access Codes Per Callbox
                  </Typography>
                </div>
                <div>
                  <CardActions>
                    <StripeCheckout
                      name="Buzz Jarvis"
                      amount={1699}
                      description={`Only $16.99 a month`}
                      currency="USD"
                      panelLabel="Subscribe Now" //Submit button in modal
                      image="https://i.postimg.cc/pTMFdqdL/noun-interphone-3907992.png"
                      token={async (token) => onToken({ token, amount: 1699 })}
                      stripeKey={"pk_live_Xyw2DT9ExfQiRGkPSot9JH2J00fo5YItIK"} //pk_live_Xyw2DT9ExfQiRGkPSot9JH2J00fo5YItIK
                    >
                      <SubscribeButton
                        style={{
                          backgroundColor: `${
                            !auth.subscription
                              ? "#6262a0"
                              : auth.plan === "premium"
                              ? "lightcoral"
                              : "#6262a0"
                          }`,
                        }}
                      >
                        {isLoading ? (
                          <CircleLoader />
                        ) : (
                          `${
                            !auth.subscription
                              ? "Subscribe"
                              : auth.plan === "premium"
                              ? "Update Credit Card"
                              : "Upgrade"
                          }`
                        )}
                      </SubscribeButton>
                    </StripeCheckout>
                  </CardActions>
                </div>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <div>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Customized
                  </Typography>
                  <Typography
                    className={classes.price}
                    style={{ fontSize: "62px", marginBottom: "10px" }}
                  >
                    Contact
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckBoxOutlinedIcon
                      style={{ margin: "0 10px 0 20px", color: "#1dbe74" }}
                    />{" "}
                    500+ Buzz-Ins Per Month
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckBoxOutlinedIcon
                      style={{ margin: "0 10px 0 20px", color: "#1dbe74" }}
                    />{" "}
                    5+ Callboxes
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckBoxOutlinedIcon
                      style={{ margin: "0 10px 0 20px", color: "#1dbe74" }}
                    />{" "}
                    6+ Access Codes Per Callbox
                  </Typography>
                </div>
                <div>
                  <CardActions>
                    <SubscribeButton
                      style={{ backgroundColor: "#6262a0" }}
                      onClick={() => goToPageContact()}
                    >
                      Contact Us
                    </SubscribeButton>
                  </CardActions>
                </div>
              </CardContent>
            </Card>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            {auth.subscription && (
              <CancelButton onClick={() => cancelSub()}>
                Cancel Subscription
              </CancelButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const SubscribeButton = styled.button`
  min-height: 50px;
  min-width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #7272aa;
  }
`;

const CancelButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: red;
  }
`;

export default withRouter(BillingComp);
