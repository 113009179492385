import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import swal from "sweetalert";
import CardContent from "@material-ui/core/CardContent";
import { Link, withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import axios from "axios";
import { copyReferralLinkAction } from "../helpers/GoogleAnalytics";
import ReferralsModal from "./ReferralsModal";

const useStyles = makeStyles((theme) => ({
  innerCards: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    width: "350px",
    margin: "10px 0",
    minHeight: "305px",
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
  cardContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 14,
    textTransform: "uppercase",
    textAlign: "center",
    color: "#6262a0",
  },
  price: {
    fontSize: 50,
    color: "#6262a0",
    fontWeight: 600,
    textAlign: "center",
  },
  pos: {
    display: "flex",
    marginBottom: 12,
  },
}));

const ActionBox = ({ auth, history, counts }) => {
  const classes = useStyles();
  const [referrals, setReferrals] = useState([]);
  const [inviteUrl, setInviteUrl] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const setUpRef = async () => {
    try {
      const userId = auth.id ?? auth._id;
      const { data } = await axios.get(`/api/${userId}/ref`);
      setReferrals(data);
      setInviteUrl(`https://app.buzzjarvis.com/r/register?ref=${auth._id}`);
    } catch (err) {
      console.log("GET REFERRAL ERROR", err);
    }
  };

  const copyReferral = () => {
    copyReferralLinkAction();
    setIsCopied(true);
  };

  const goToPage = (path) => {
    history.push(path);
  };

  const checkBoxCount = () => {
    if (!auth.subscription && !auth.isTrial) {
      swal("Get Your first 14 days FREE!", {
        title: "You'll need a subscription to create callboxes.",
        buttons: false,
        timer: 3000,
      });

      return "/account";
    }

    return "/properties/add";
  };

  useEffect(() => {
    setUpRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={classes.cards}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.innerCards}>
              <div className={classes.innerCards}>
                {auth && auth.admin ? (
                  <Link to="/admin">
                    <ActivityButton>Admin Dashboard</ActivityButton>
                  </Link>
                ) : (
                  <Link to="/dashboard">
                    <ActivityButton>Property Activity</ActivityButton>
                  </Link>
                )}
                <AddCallboxButton onClick={() => goToPage(checkBoxCount())}>
                  Add Callbox
                </AddCallboxButton>
              </div>

              <div>
                <div>
                  <TextField
                    label="Referral Link"
                    value={inviteUrl}
                    style={{ width: "240px", marginTop: "40px" }}
                    InputProps={{
                      endAdornment: (
                        <CopyToClipboard
                          text={inviteUrl}
                          onCopy={() => copyReferral()}
                        >
                          <FileCopyIcon className="hover" />
                        </CopyToClipboard>
                      ),
                    }}
                  />
                </div>
                <div style={{ height: "30px", textAlign: "center" }}>
                  {isCopied && (
                    <span style={{ color: "green" }}>Copied to Clipboard!</span>
                  )}
                </div>

                <div
                  style={{
                    height: "40px",

                    border: "1px solid #6262a0",
                    textAlign: "center",
                    padding: "8px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ReferralsModal referrals={referrals} />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const ActivityButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #6262a0;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #7272aa;
  }
`;

const AddCallboxButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: lightcoral;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #f6b2b2;
    color: #fff;
  }
`;

export default withRouter(ActionBox);
