import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { PageView, GA_Event } from "../helpers/GoogleAnalytics";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "87px",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  contactFormDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  inputDiv: {
    width: "280px",
    marginTop: "20px",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
  },
  indiContactDiv: {
    margin: "0 70px",
    [theme.breakpoints.down("sm")]: {
      margin: "unset",
    },
  },
  imageStyle: {
    height: "400px",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "230px",
    },
  },
}));

const Contact = () => {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
    error: "",
    isLoading: false,
    submitted: false,
  });

  useEffect(() => {
    PageView();
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { name, email, message, isLoading, submitted, error } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const sendEmail = () => {
    if (
      email.trim().length < 1 ||
      name.trim().length < 1 ||
      message.trim().length < 1
    ) {
      setValues({
        ...values,
        error: "Please complete all fields on the form",
        isLoading: false,
      });
    } else {
      GA_Event("User Interaction", "User Clicked Send Message");
      setValues({ ...values, isLoading: true });
      axios
        .post("/api/contact/us", { name, email, message })
        .then(() => {
          setValues({
            ...values,
            name: "",
            email: "",
            message: "",
            error: "",
            isLoading: false,
            submitted: true,
          });
        })
        .catch((error) => {
          console.log("SENDINGN EMAIL ERROR", error);
          setValues({
            ...values,
            error: "Unable to send email",
            isLoading: false,
          });
        });
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className="row">
        <div
          style={{
            fontSize: "38px",
            fontWeight: "bold",
            color: "#6262a0",
            textAlign: "center",
          }}
        >
          {!submitted && "Contact Us"}
        </div>
      </div>
      <div>
        {submitted ? (
          <span
            style={{
              color: "green",
              textAlign: "center",
              width: "200px",
              fontWeight: "800",
              marginTop: "30px",
            }}
          >
            Thank you for your message, someone from the team will get back to
            you as soon as possible.
          </span>
        ) : (
          <React.Fragment>
            <form noValidate autoComplete="off" className={classes.formDiv}>
              <TextField
                label="Name"
                variant="outlined"
                disabled={isLoading}
                value={name}
                onChange={handleChange("name")}
                className={classes.inputDiv}
              />
              <TextField
                label="E-mail"
                variant="outlined"
                disabled={isLoading}
                value={email}
                onChange={handleChange("email")}
                className={classes.inputDiv}
              />
              <TextField
                label="Message"
                disabled={isLoading}
                value={message}
                multiline
                rows={5}
                variant="outlined"
                onChange={handleChange("message")}
                className={classes.inputDiv}
              />
            </form>
            <SubscribeButton
              className={classes.button}
              disabled={isLoading}
              color="secondary"
              variant="contained"
              onClick={sendEmail}
            >
              Send
            </SubscribeButton>
            {error && <span>{error}</span>}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const SubscribeButton = styled.button`
  min-height: 50px;
  min-width: 100%;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #6262a0;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #7272aa;
  }
`;

export default Contact;
