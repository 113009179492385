import React, { Component } from "react";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Loading from "../helpers/Loading";
import { HoverDiv } from "./LoginPage";
import { PageView, newRegistrationsAction } from "../helpers/GoogleAnalytics";
import CircleLoader from "../helpers/CircleLoader";
import { authenticate, isAuth } from "../../utils/AuthHelper";

const RegisterButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #7272aa;
  }
`;

const StyledHeading = styled("h2")`
  font-size: 25px;
  font-weight: 700;
  margin-top: 2vh;
  margin-bottom: 24px;
  text-align: center;
  line-height: 1.1;
  color: #b6b6b5;
`;

export const GreenButton = styled("button")`
  align-items: center;
  user-select: none;
  display: inline-flex;
  justify-content: center;
  padding: 6px 16px;
  flex-shrink: 0;
  border-radius: 3px;
  font-weight: 500;
  background: rgba(235, 87, 87, 0.03);
  color: #00a591;
  border: 1px solid #00a591;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  width: 170px;
  margin-top: 6px;
  margin-bottom: 12px;
  cursor: pointer;
  &:hover {
    background-color: #00a591;
    color: #fff;
  }
`;

const StyledRedSpan = styled("span")`
  color: red;
`;

const StyledGreenSpan = styled("span")`
  color: green;
`;

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      name: "",
      email: "",
      password: "",
      password2: "",
      phone: "",
      signUpError: "",
      signUpSuccess: "",
      ref: "",
      scan: "",
    };

    this._isMounted = false;
  }

  timer = () =>
    setTimeout(() => {
      const { name, email, password } = this.state;

      const userObj = {
        name,
        email,
        password,
      };

      axios
        .post("/api/login", userObj)
        .then((res) => {
          if (res.data.success) {
            this._isMounted &&
              this.setState({
                name: "",
                email: "",
                password: "",
                password2: "",
                signUpError: "",
                // isLoading: false,
              });
          } else {
            this._isMounted &&
              this.setState({
                signInError: res.data.message,
                // isLoading: false,
              });
          }
        })
        .catch((err) => {
          console.log(err);
          this._isMounted &&
            this.setState({
              signUpError:
                "Email and Password does not match or This is a gmail account or Account does not exist.",
              isLoading: false,
            });
        });

      this.props.history.push("/register/new");
    }, 1000);

  componentDidMount = () => {
    this._isMounted = true;
    const query = new URLSearchParams(this.props.location.search);
    const ref = query.get("ref");
    const scan = query.get("scan");
    window.scrollTo(0, 0);
    PageView();
    if (this.state.isLoading) {
      this._isMounted &&
        this.setState({
          isLoading: false,
          ref,
          scan,
        });
    }
  };

  setErrorMessage = (err) => {
    this._isMounted &&
      this.setState({
        signUpError: err,
        isLoading: false,
      });
  };

  setLoading = (loadingState) => {
    this._isMounted &&
      this.setState({
        isLoading: Boolean(loadingState),
      });
  };

  informParent = (response) => {
    authenticate(response, () => {
      this.setLoading(false);
      isAuth()
        ? this.props.history.push("/home")
        : this.props.history.push("/login");
    });
  };

  handleChange = (event) => {
    this._isMounted &&
      this.setState({
        [event.target.name]: event.target.value,
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    newRegistrationsAction();

    this._isMounted &&
      this.setState({
        isLoading: true,
      });

    const { name, email, password, ref, phone, scan } = this.state;

    const userObj = {
      name: name.toLowerCase(),
      email: email.toLowerCase(),
      phoneNumber: phone,
      password: password,
      ref,
      scan,
    };

    axios
      .post("/api/register", userObj)
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            const { name, email, password } = this.state;

            const userObj = {
              name,
              email,
              password,
            };

            axios
              .post("/api/login", userObj)
              .then((res) => {
                if (res.data.success) {
                  authenticate(res, () => {
                    this._isMounted &&
                      this.setState({
                        name: "",
                        email: "",
                        phone: "",
                        password: "",
                        password2: "",
                        signUpError: "",
                        // isLoading: false,
                      });
                  });
                } else {
                  this._isMounted &&
                    this.setState({
                      signInError: res.data.message,
                    });
                }
              })
              .catch((err) => {
                console.log(err);
                this._isMounted &&
                  this.setState({
                    signUpError: err.response.data.message,
                    isLoading: false,
                  });
              });

            // this.props.fetchUser();

            this.props.history.push("/register/new");
          }, 1000);
        } else {
          this._isMounted &&
            this.setState({
              signUpError: res.data.message,
              isLoading: false,
            });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          signUpError: err.response.data.message,
          isLoading: false,
        });
      });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    clearTimeout(this.timer);
  };

  redirectToSuccess = () => {
    this.props.history.push("/register/new");
  };

  render() {
    const { signUpError, isLoading, signUpSuccess } = this.state;

    return (
      <div>
        <div id="loginPage">
          <div id="imageSection">
            <img
              src="https://i.postimg.cc/MZDqNv0M/newregisterpng.png"
              alt="register-page"
              style={{ width: "800px", marginTop: "25px" }}
            />
          </div>
          {isAuth() && isAuth().plan ? <Redirect to="/home" /> : null}
          <div id="loginSection">
            <StyledHeading>Register</StyledHeading>
            {isLoading ? (
              <CircleLoader />
            ) : (
              <>
                <form onSubmit={this.handleSubmit}>
                  <br />

                  <TextField
                    label="Full Name"
                    variant="outlined"
                    type="text"
                    name="name"
                    disabled={this.state.isLoading}
                    placeholder="John Doe..."
                    value={this.state.name}
                    required
                    onChange={this.handleChange}
                    style={{ width: "280px" }}
                  />

                  <br />

                  <TextField
                    variant="outlined"
                    label="Email"
                    type="email"
                    name="email"
                    disabled={this.state.isLoading}
                    placeholder="example@example.com..."
                    value={this.state.email}
                    required
                    onChange={this.handleChange}
                    style={{ width: "280px", marginTop: "20px" }}
                  />

                  <br />

                  <TextField
                    variant="outlined"
                    label="Password"
                    type="password"
                    name="password"
                    disabled={this.state.isLoading}
                    placeholder="Enter your password here..."
                    value={this.state.password}
                    required
                    onChange={this.handleChange}
                    style={{ width: "280px", marginTop: "20px" }}
                  />

                  <br />

                  <TextField
                    variant="outlined"
                    label="Phone Number"
                    type="number"
                    name="phone"
                    required
                    disabled={this.state.isLoading}
                    placeholder="Enter your phone number here..."
                    value={this.state.phone}
                    onChange={this.handleChange}
                    style={{ width: "280px", marginTop: "20px" }}
                  />

                  {isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <RegisterButton>
                        <Loading />
                      </RegisterButton>
                    </div>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <RegisterButton
                        style={{ backgroundColor: "#6262a0" }}
                        onClick={this.handleSubmit}
                      >
                        Register
                      </RegisterButton>
                    </div>
                  )}
                </form>

                {signUpError ? (
                  <div
                    style={{
                      width: "280px",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    <StyledRedSpan>{signUpError}</StyledRedSpan>
                  </div>
                ) : null}

                {signUpSuccess ? (
                  <div>
                    <StyledGreenSpan>{signUpSuccess}</StyledGreenSpan>
                  </div>
                ) : null}

                <div
                  style={{
                    color: "#b6b6b5",
                    width: 260,
                    marginTop: 20,
                    fontSize: 12,
                  }}
                >
                  By registering, you agree to receive automated transactional
                  messages. Txt and data rates may apply. Reply STOP to end or
                  HELP for help. For more info, visit our{" "}
                  <Link to="/terms-of-service">TOS</Link>.{" "}
                </div>

                {/* <div>
                  <GoogleButton
                    informParent={this.informParent}
                    setErrorMessage={this.setErrorMessage}
                    setLoading={this.setLoading}
                  />
                </div> */}

                <div style={{ marginTop: "15px" }}>
                  <span style={{ color: "#b6b6b5" }}>
                    Already have an account?
                  </span>{" "}
                  <Link to="/login" style={{ color: "#0080AD" }}>
                    <HoverDiv>Log In</HoverDiv>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterPage;
