import axios from "axios";
import { FETCH_USER, FETCH_PROPS, FETCH_PROPERTY } from "./types";

export const fetchUser = () => async (dispatch) => {
  const res = await axios.get("/api/current_user");

  dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchProps = () => async (dispatch) => {
  const res = await axios.get("/api/properties");

  dispatch({ type: FETCH_PROPS, payload: res.data });
};

export const fetchCounts = () => async (dispatch) => {
  const res = await axios.get("/api/counts");

  dispatch({ type: FETCH_PROPS, payload: res.data });
};

export const fetchOneProp = (propId) => async (dispatch) => {
  const res = await axios.get(`/api/${propId}/property`);

  dispatch({ type: FETCH_PROPERTY, payload: res.data });
};

export const handleCallBoxUpdate = (newPropp) => async (dispatch) => {
  const update = await axios.post("/api/options", newPropp);

  if (update.data.success) {
    const res = await axios.get("/api/properties");

    dispatch({ type: FETCH_PROPS, payload: res.data });
  }
};

export const handleToken = (tokenItems) => async (dispatch) => {
  // this call the back end and sends the token along with the call
  // this is passed in and added in as a second argument
  const res = await axios.post("/api/subscriptions/new", tokenItems);

  // sends back(dispatchs) action type FETCH_USER with the response data
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const handleTokenTwo = (tokenItems) => async (dispatch) => {
  // this call the back end and sends the token along with the call
  // this is passed in and added in as a second argument
  const res = await axios.post("/api/subscriptions/new", tokenItems);

  // sends back(dispatchs) action type FETCH_USER with the response data
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const handleSubscriptionUpdate = (tokenItems) => async (dispatch) => {
  // this call the back end and sends the token along with the call
  // this is passed in and added in as a second argument
  const res = await axios.post("/api/subscription/update", tokenItems);

  // sends back(dispatchs) action type FETCH_USER with the response data
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const handleCancel = () => async (dispatch) => {
  // this call the back end and sends the token along with the call
  // this is passed in and added in as a second argument
  const res = await axios.post("/api/subscription/cancel");

  // sends back(dispatchs) action type FETCH_USER with the response data
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const submitProps = (propObj) => async (dispatch) => {
  // console.log(formValues);

  const res = await axios.post("/api/property/add", propObj);

  dispatch({ type: FETCH_USER, payload: res.data });
};

export const updateProps = (propObj) => async (dispatch) => {
  // console.log(formValues);

  const res = await axios.post("/api/property/add", propObj);

  dispatch({ type: FETCH_USER, payload: res.data });
};
