import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import MainInput from "../helpers/MainInput";
import Button from "@material-ui/core/Button";
import { PageView, GA_Event } from "../helpers/GoogleAnalytics";
import Switch from "@material-ui/core/Switch";
import CircleLoader from "../helpers/CircleLoader";
import PremiumAccessCode from "./PremiumAccessCode";
import { isAuth } from "../../utils/AuthHelper";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "lightcoral",
    "&$checked": {
      color: "#f6b2b2",
    },
    "&$checked + $track": {
      backgroundColor: "#f6b2b2",
    },
  },
  checked: {},
  track: {},
})(Switch);

const EditProperty = ({ history }) => {
  const [values, setValues] = useState({
    propName: "",
    address: "",
    securityCode: "",
    enableSecurity: false,
    gateCodeDialNumber: "",
    message: "",
    customerPhone: "",
    errorMsg: "",
    propData: [],
    propId: "",
  });
  const [checkedA, setCheckedA] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amtAllowed, setAmtAllowed] = useState(2);

  const {
    propId,
    errorMsg,
    propName,
    address,
    securityCode,
    gateCodeDialNumber,
    message,
    customerPhone,
  } = values;

  const [formValues, setFormValues] = useState([{ reason: "", code: "" }]);

  const handleSecurityChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    if (amtAllowed <= formValues.length) {
      setValues({
        ...values,
        errorMsg:
          "You have reached your limit for access codes, please upgrade or contact us for more.",
      });
      return;
    }

    setFormValues([...formValues, { reason: "", code: "" }]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setValues({
      ...values,
      errorMsg: "",
    });
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleToggle = (bool) => {
    setCheckedA(bool);
    if (formValues.length < 1) {
      setFormValues([...formValues, { reason: "", code: "" }]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    GA_Event(
      "Product interactions",
      `Updated Property: ${propName} - ${propId}`,
      `By User: ${isAuth()._id}`
    );

    if (
      !propName.trim() ||
      propName.trim() === "" ||
      !address ||
      !customerPhone.trim() ||
      customerPhone.trim() === "" ||
      !gateCodeDialNumber
    ) {
      setValues({ ...values, errorMsg: "Please fill in all required fields" });
      return;
    }

    setIsLoading(true);

    const propObj = {
      name: propName,
      address,
      securityCode,
      gateCodeDialNumber,
      customerPhone,
      codeRequired: checkedA,
      propId,
      securityCodes: formValues,
    };

    try {
      const { data } = await axios.post("/api/property/edit", propObj);
      console.log(data);
      if (data.success) {
        history.push("/home");
      } else {
        setValues({
          ...values,
          errorMsg: data.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      setValues({
        ...values,
        errorMsg: "Invalid Customer or Callbox Number, U.S. numbers.",
      });
      setIsLoading(false);
      console.log(err.message);
    }
  };

  const deleteProperty = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`/api/${propId}/property/delete`);
      if (data.success) {
        history.push("/home");
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setValues({
        ...values,
        errMsg:
          "Unable to Delete Callbox at this moment, please try again later.",
      });
    }
  };

  const assignProps = () => {
    const { name, address, securityCode, gateCodeDialNumber, customerPhone } =
      history.location.state.property;

    setValues({
      ...values,
      propName: name,
      address,
      securityCode,
      gateCodeDialNumber,
      customerPhone,
      propId: history.location.state.propertyId,
    });
  };

  const assignAccessCodes = async () => {
    try {
      const { data } = await axios.get(
        `/api/${history.location.state.propertyId}/security`
      );
      if (data.success) {
        let accessCodeArry = [];
        if (data.message.length) {
          for (let i = 0; i < data.message.length; i++) {
            const codeObject = {
              reason: data.message[i].reason,
              code: data.message[i].code,
              accessCodeId: data.message[i]._id,
            };
            accessCodeArry.push(codeObject);
          }
          setFormValues(accessCodeArry);
        }
      } else {
        setValues({
          ...values,
          errorMsg: data.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      setValues({
        ...values,
        errorMsg: "Invalid Customer or Callbox Number, U.S. numbers.",
      });
      setIsLoading(false);
      console.log(err.message);
    }
  };

  useEffect(() => {
    PageView();
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }

    const alld = isAuth().plan === "premium" ? 5 : 2;
    setAmtAllowed(alld);
    setIsLoading(true);
    setCheckedA(history.location.state.isSecure);
    if (history.location.state.property.codeRequired) {
      assignAccessCodes();
    }
    assignProps();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
      }}
    >
      {isLoading ? (
        <CircleLoader />
      ) : (
        <div>
          <form onSubmit={handleSubmit}>
            <StyledHeading>Edit Property</StyledHeading>
            <br />
            <MainInput
              label="Property name *"
              type="text"
              name="name"
              disabled={isLoading}
              help="A unique way for you to identify this specific property"
              placeholder="ex. Walton Riverwood Apartments Call Box..."
              value={propName}
              required
              onChange={handleChange("propName")}
            />
            <br />
            <MainInput
              label="Property Address *"
              type="text"
              name="address"
              disabled={isLoading}
              help="This is used only for your assistance in remembering where the call box/gate is located"
              placeholder="ex. 2000 Univerisity Pkwy... Just to help you identify properties..."
              value={address}
              required
              onChange={handleChange("address")}
            />
            <br />
            <MainInput
              label="Your Phone Number *"
              type="text"
              name="customerPhone"
              disabled={isLoading}
              help="This your phonenumber or any number that will be used to send you updates such as when the door/gate is open."
              placeholder="ex. 4041231234... notifications will be texted to this number"
              value={customerPhone}
              required
              onChange={handleChange("customerPhone")}
            />

            <br />
            <MainInput
              label="Cell Phone Buzz-In Number *"
              type="text"
              name="gateCodeDialNumber"
              disabled={isLoading}
              help="This is the number provided to you from the property management to dial on your cell phone to buzz open the gate for your guest to come in, ...ie. '9'"
              placeholder="ex. '9'... Number that you'd dial at call box to open gate..."
              value={gateCodeDialNumber}
              required
              onChange={handleChange("gateCodeDialNumber")}
            />
            <br />

            <span>
              <PurpleSwitch
                checked={checkedA}
                onChange={() => handleToggle(!checkedA)}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              Require Access Code(s)?
            </span>

            {checkedA ? (
              <PremiumAccessCode
                handleSecurityChange={handleSecurityChange}
                isLoading={isLoading}
                setFormValues={setFormValues}
                formValues={formValues}
                addFormFields={addFormFields}
                removeFormFields={removeFormFields}
              />
            ) : null}

            <br />
            <div style={{ textAlign: "center", marginBottom: "25px" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                value="Submit"
                onClick={handleSubmit}
                style={{
                  height: "50px",
                  width: "100px",
                  marginTop: "20px",
                  cursor: "pointer",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "#6262a0",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "600",
                  transition:
                    "all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s",
                  "&:hover": {
                    backgroundColor: "#7272aa",
                  },
                }}
              >
                {isLoading ? "Loading..." : "Update"}
              </Button>
              <Button
                id="red"
                variant="contained"
                onClick={deleteProperty}
                style={{
                  height: "50px",
                  width: "100px",
                  marginTop: "20px",
                  cursor: "pointer",
                  marginLeft: "25px",
                  border: "none",
                  borderRadius: "5px",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "600",
                  transition:
                    "all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s",
                  "&:hover": {
                    backgroundColor: "red",
                  },
                }}
              >
                Delete
              </Button>
            </div>
            <div
              style={{
                fontSize: "10px",
                color: "red",
                textAlign: "center",
                width: "280px",
              }}
            >
              <span>{errorMsg && errorMsg}</span>
            </div>
            <div
              style={{
                fontSize: "10px",
                color: "green",
                textAlign: "center",
                width: "280px",
              }}
            >
              <span>{message && message}</span>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const StyledHeading = styled("h2")`
  font-size: 22px;
  font-weight: 700;
  margin-top: 2vh;
  margin-bottom: 24px;
  text-align: center;
  line-height: 1.1;
  color: #b6b6b5;
`;

export default withRouter(EditProperty);
