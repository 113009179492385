import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

class errorPage extends Component {
  goBack = async () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <h1>Oops looks like that page does not exist.</h1>
        <Link to="/properties/add">
          <Button variant="contained" color="primary">
            Go Back
          </Button>
        </Link>
      </div>
    );
  }
}

export default withRouter(errorPage);
