import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GeneralComp from "../contentComps/GeneralComp";
import BillingComp from "../contentComps/BillingComp";
import { isAuth } from "../../utils/AuthHelper";

const Profile = () => {
  const auth = isAuth();
  const [value, setValue] = useState(1);
  const [nextPay, setNextPay] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToBilling = () => {
    setValue(1);
  };

  const renderTabComponent = () => {
    switch (value) {
      case 0:
        return <GeneralComp auth={auth} />;
      case 1:
        return <BillingComp auth={auth} />;
      default:
        return <GeneralComp auth={auth} />;
    }
  };

  const addSuffix = (i) => {
    const j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };

  const subs = async () => {
    setIsLoading(true);
    if (auth.subscription) {
      const {
        data: { nextPayment },
      } = await axios.get("/api/subscriptions/get");
      setNextPay(nextPayment);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    subs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="row" style={{ display: "flex", flexDirection: "column" }}>
        <PageTitle>
          Your Plan:
          <span
            style={{ marginLeft: "10px", color: "#6262a0", cursor: "pointer" }}
            onClick={() => goToBilling()}
          >
            {!auth.subscription && !auth.isTrial
              ? "Free"
              : auth.plan === "premium"
              ? "Premium"
              : auth.isTrial
              ? `Free Trial ends ${moment(auth.freeTrialEndDate)
                  .startOf("minute")
                  .fromNow()}`
              : "Basic"}
          </span>
        </PageTitle>

        {auth.subscription ? (
          <PageTitle>
            Next Payment:
            <span
              style={{
                marginLeft: "10px",
                color: "#6262a0",
                cursor: "pointer",
              }}
              onClick={() => goToBilling()}
            >
              {isLoading
                ? "Loading..."
                : `${nextPay.month} ${addSuffix(nextPay.day)}`}
            </span>
          </PageTitle>
        ) : null}
      </div>
      <div style={{ marginTop: "15px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="General" />
          <Tab
            label={isAuth().isTrial ? "Start Your Subscription" : "Billing"}
          />
        </Tabs>
      </div>
      <div style={{ marginTop: "15px" }}>{renderTabComponent()}</div>
    </div>
  );
};

const PageTitle = styled.span`
  font-size: 16px;
  color: gray;
  font-weight: 600;
`;

export default Profile;
