import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import axios from "axios";
import UserDetailCard from "./UserDetailCard";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  moreData: {
    margin: "60px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

export default function CustomizedTables({ userDetails }) {
  const classes = useStyles();
  const [currUser, setCurrUser] = useState("");

  const GoToUser = async (user) => {
    // console.log(user._id);
    const { data } = await axios.get(`/api/${user._id}/admin/property`);
    setCurrUser(data);
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell align="right">Name</StyledTableCell>
              <StyledTableCell align="right">Phone Number</StyledTableCell>
              <StyledTableCell align="right">Status</StyledTableCell>
              <StyledTableCell align="right">Source</StyledTableCell>
              <StyledTableCell align="right">
                Free Trial End Date
              </StyledTableCell>
              <StyledTableCell align="right">
                Number Of Properties
              </StyledTableCell>
              <StyledTableCell align="right">Created</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userDetails &&
              userDetails.map((user) => (
                <StyledTableRow
                  hover
                  key={user._id}
                  onClick={GoToUser.bind(this, user)}
                >
                  <StyledTableCell component="th" scope="row">
                    {user.userEmail}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {user.userName}
                  </StyledTableCell>
                  <StyledTableCell align="right">{user.phone}</StyledTableCell>
                  <StyledTableCell align="right">{user.status}</StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{ textTransform: "capitalize" }}
                  >
                    {user.scan}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {user.trialEnd &&
                      moment(user.trialEnd).startOf("minute").fromNow()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {user.propertyCount}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {moment(user.created).startOf("minute").fromNow()}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      <div className={classes.moreData}>
        {currUser && currUser.userProp.length
          ? currUser.userProp.map((usr) => {
              return (
                <UserDetailCard key={usr._id} usr={usr} others={currUser} />
              );
            })
          : null}
      </div>
    </div>
  );
}
