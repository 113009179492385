import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// material ui
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import LoginPage from "./components/auth/LoginPage";
import RegisterPage from "./components/auth/RegisterPage";
import RefRegisterPage from "./components/auth/RefRegisterPage";
import RegisterSuccessPage from "./components/auth/RegisterSuccessPage";
// import Layout from "./components/Layout";
import AddProperty from "./components/contentComps/AddProperty";
import Home from "./components/contentComps/Home";
// import Automations from "./components/contentComps/Automations";
import Header from "./components/main/Header";
import ErrorPage from "./components/main/errorPage";
import SubbedRouteWrapper from "./components/auth/SubbedRouteWrapper";
import AdminRouteWrapper from "./components/auth/AdminRouteWrapper";
import MemberRouteWrapper from "./components/auth/MemberRouteWrapper";
import EditProperty from "./components/contentComps/EditProperty";
import Super from "./components/main/Super";
import Dashboard from "./components/main/Dashboard";
// const Redirect = require("react-router").Redirect;
import ReactGA from "react-ga";
import Footer from "./components/main/Footer";
import AllDashboard from "./components/main/AllDashboard";
import Contact from "./components/main/Contact";
import FAQ from "./components/main/FAQ";
import Terms from "./components/main/terms";
import Profile from "./components/main/Profile";
import Forgot from "./components/contentComps/Forgot";
import Reset from "./components/contentComps/Reset";
import UsersDashboard from "./components/main/UsersDashboard";

function initializeAnalytics() {
  ReactGA.initialize("UA-151865533-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends Component {
  componentDidMount() {
    initializeAnalytics();
  }

  render() {
    return (
      <div>
        <CssBaseline />
        <BrowserRouter>
          <div className="App Site">
            <div className="Site-content">
              <Header />
              <Container>
                <Switch>
                  <Route exact path="/terms-of-service" component={Terms} />
                  <Route exact path="/login" component={LoginPage} />
                  <Route exact path="/register" component={RegisterPage} />
                  <Route exact path="/r/register" component={RefRegisterPage} />
                  <Route
                    exact
                    path="/register/new"
                    component={RegisterSuccessPage}
                  />
                  <MemberRouteWrapper exact path="/home" component={Home} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/forgot" component={Forgot} />
                  <Route exact path="/reset/:token" component={Reset} />
                  <Route exact path="/faq" component={FAQ} />
                  <MemberRouteWrapper
                    exact
                    path="/account"
                    component={Profile}
                  />
                  <AdminRouteWrapper exact path="/admin" component={Super} />
                  <SubbedRouteWrapper
                    exact
                    path="/dashboard"
                    component={Dashboard}
                  />
                  <AdminRouteWrapper
                    exact
                    path="/dashboard/all"
                    component={AllDashboard}
                  />
                  <AdminRouteWrapper
                    exact
                    path="/dashboard/users"
                    component={UsersDashboard}
                  />
                  <SubbedRouteWrapper
                    exact
                    path="/property/:propId/edit"
                    component={EditProperty}
                  />
                  <SubbedRouteWrapper
                    exact
                    path="/properties/add"
                    component={AddProperty}
                  />
                  <MemberRouteWrapper exact path="/" component={Home} />
                  <ErrorPage />
                </Switch>
              </Container>
            </div>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
