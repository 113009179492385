import cookie from "js-cookie";
import axios from "axios";

// set in cookie
export const setCookie = (key, value) => {
  if (window !== "undefined") {
    cookie.set(key, value, {
      expires: 1,
    });
  }
};

// remove from cookie
export const removeCookie = (key) => {
  if (window !== "undefined") {
    cookie.remove(key, {
      expires: 1,
    });
  }
};

// get from cookie
export const getCookie = (key) => {
  if (window !== "undefined") {
    return cookie.get(key);
  }
};

// set in localstorage
export const setLocalStorage = (key, value) => {
  if (window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// get from localstorage
export const getLocalStorage = (key) => {
  if (window !== "undefined") {
    const res = localStorage.getItem(key);
    return JSON.parse(res);
  }
};

// remove from localstorage
export const removeLocalStorage = (key) => {
  if (window !== "undefined") {
    localStorage.removeItem(key);
  }
};

// authenticate user by passing data to cookie and localstorage during signin
export const authenticate = async (response, next) => {
  // console.log(response);
  if (window !== "undefined") {
    setLocalStorage("user", response.data.user);
    next();
  }
};

export const updatedUser = (response, next) => {
  if (window !== "undefined") {
    setLocalStorage("user", response.data);
  }
  next();
};

// access user info from localstorage
export const isAuth = () => {
  if (window !== "undefined") {
    if (localStorage.getItem("user") !== "undefined" || null) {
      return JSON.parse(localStorage.getItem("user"));
    } else {
      return false;
    }
  }
};

export const signOut = async (next) => {
  removeLocalStorage("user");
  try {
    await axios.get("/api/logout");
  } catch (err) {
    setTimeout(() => {
      window.location = "/login";
    }, 500);
  }
  next();
};
