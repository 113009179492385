import React from "react";

const Terms = () => {
  return (
    <div
      className="
        max-auto
        xl:px-20
        md:px-10
        sm:px-2
        px-4
        mt-16
        "
    >
      <div
        className="text-lg md:text-2xl font-black"
        style={{ fontWeight: 900, fontSize: 28, marginTop: 30 }}
      >
        Terms of Service
      </div>
      <p>Last Updated: [09/05/2023]</p>

      <h2 className="text-lg font-bold mt-3">1. Acceptance of Terms</h2>
      <p>
        Welcome to [BuzzJarvis] ({`"we," "us," or "our"`}). By using our website
        or services, you agree to abide by these Terms of Service. If you do not
        agree with these terms, please do not use our website or services.
      </p>

      <h2 className="text-lg font-bold mt-3">2. Use of Services</h2>
      <p>
        Our services may only be used for lawful purposes. You agree not to
        engage in any activities that violate any applicable laws or regulations
        while using our services.
      </p>

      <h2 className="text-lg font-bold mt-3">3. User Accounts</h2>
      <p>
        You may be required to create an account to access certain services. You
        are responsible for maintaining the confidentiality of your account
        credentials and for all activities that occur under your account.
      </p>

      <h2 className="text-lg font-bold mt-3">4. Intellectual Property</h2>
      <p>
        All content and materials on our website are protected by copyright and
        other intellectual property laws. You may not copy, reproduce, or
        distribute our content without our written permission.
      </p>

      <h2 className="text-lg font-bold mt-3">5. Privacy</h2>
      <p>
        Our privacy practices are outlined in our Privacy Policy. By using our
        services, you consent to the collection and use of your information as
        described in the Privacy Policy.
      </p>

      <h2 className="text-lg font-bold mt-3">6. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your access to our services
        at our discretion, without notice, for any violation of these Terms of
        Service.
      </p>

      <h2 className="text-lg font-bold mt-3">7. Disclaimers</h2>
      <p>
        Our services are provided {"as is"} and without warranties of any kind,
        either express or implied. We do not guarantee the accuracy or
        availability of our services.
      </p>

      <h2 className="text-lg font-bold mt-3">8. Limitation of Liability</h2>
      <p>
        We shall not be liable for any indirect, incidental, special, or
        consequential damages arising out of or in connection with your use of
        our services.
      </p>

      <h2 className="text-lg font-bold mt-3">9. Governing Law</h2>
      <p>
        These Terms of Service are governed by and construed in accordance with
        the laws of [Your Jurisdiction]. Any disputes arising from these terms
        shall be subject to the exclusive jurisdiction of the courts in [Your
        Jurisdiction].
      </p>

      <h2 className="text-lg font-bold mt-3">10. Changes to Terms</h2>
      <p>
        We may update these Terms of Service to reflect changes in our practices
        or for legal and regulatory reasons. When we make changes, we will
        update the {"Last Updated"} date at the top of this document.
      </p>

      <h2 className="text-lg font-bold mt-3">11. Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms of Service,
        please contact us at [Contact Information].
      </p>

      <h2 className="text-lg font-bold mt-3">12. Text Messaging</h2>
      <p>
        By providing your phone number, you agree to receive text messages from
        BuzzJarvis at the number provided. You're opting in to receive text
        messages from us. These messages will ONLY keep you updated on important
        information, such as mailbox activity. You can opt out at any time by
        replying STOP. Msg & data rates may apply Message and data rates may
        apply. Reply HELP for help or STOP to cancel.
      </p>
    </div>
  );
};

export default Terms;
